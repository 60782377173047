// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */

.global-header {
    background-color: #2c3e50; /* Deep, sophisticated blue */
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: center; /* Center aligns the navigation link */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-home-link {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    transition: color 0.3s ease;

    /* Adjust the padding as needed for larger clickable area */
    padding: 5px 10px; 
}

.header-home-link:hover {
    color: #74ebd5; /* Slightly lighter shade for hover effect */
}

/* Responsive Design */
@media (max-width: 768px) {
    .global-header {
        padding: 10px; /* Slightly reduce padding for smaller screens */
    }

    .header-home-link {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB,EAAE,sCAAsC;IAC/D,wCAAwC;AAC5C;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,2BAA2B;;IAE3B,2DAA2D;IAC3D,iBAAiB;AACrB;;AAEA;IACI,cAAc,EAAE,4CAA4C;AAChE;;AAEA,sBAAsB;AACtB;IACI;QACI,aAAa,EAAE,gDAAgD;IACnE;;IAEA;QACI,eAAe,EAAE,yCAAyC;IAC9D;AACJ","sourcesContent":["/* Header.css */\n\n.global-header {\n    background-color: #2c3e50; /* Deep, sophisticated blue */\n    color: white;\n    padding: 10px 20px;\n    display: flex;\n    justify-content: center; /* Center aligns the navigation link */\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.header-home-link {\n    text-decoration: none;\n    color: white;\n    font-size: 1.2rem;\n    font-weight: 500;\n    transition: color 0.3s ease;\n\n    /* Adjust the padding as needed for larger clickable area */\n    padding: 5px 10px; \n}\n\n.header-home-link:hover {\n    color: #74ebd5; /* Slightly lighter shade for hover effect */\n}\n\n/* Responsive Design */\n@media (max-width: 768px) {\n    .global-header {\n        padding: 10px; /* Slightly reduce padding for smaller screens */\n    }\n\n    .header-home-link {\n        font-size: 1rem; /* Adjust font size for smaller screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
